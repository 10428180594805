import ResponseModel from "../Response";
import { object, string, boolean } from "yup";

export default class LoginResponse extends ResponseModel {
  schema = object({
    success: boolean().required(),
    error: object().nullable(),
    response: object({
      token: string().required()
      // user: object({
      //   username: string().required(),
      //   email: string().required(),
      //   firstName: string().required(),
      //   lastName: string().required(),
      //   role: string().required(),
      //   createdDate: string().notRequired
      //   prefs: object({
      //     units: object({
      //       distance: string().required(),
      //       height: string().required(),
      //       pressure: string().required(),
      //       rain: string().required(),
      //       snow: string().required(),
      //       speed: string().required(),
      //       temp: string().required()
      //     }).required(),
      //     time: object({
      //       format: string().required()
      //     }).required()
      //   }).required()
      // }).required()
    }).nullable()
  });

  get loggedIn() {
    return this.get("success");
  }

  get authToken() {
    return this.get("response.token");
  }
}
