import _get from "lodash.get";
import _isNil from "lodash.isnil";

export default class Response {
  apiResponse = {};
  schema = null;
  error = null;

  constructor(apiResponse: any) {
    this.apiResponse = apiResponse;
  }

  get isValid() {
    try {
      this.validate();
      return true;
    } catch (err) {
      this.error = err;
      return false;
    }
  }

  validate() {
    if (this.schema) {
      this.schema.validateSync(this.apiResponse);
    }
  }

  get success(): boolean {
    return _get(this.apiResponse, "success", false) === true;
  }

  get data(): any {
    return _get(this.apiResponse, "response", null);
  }

  get hasError(): boolean {
    if (_get(this.apiResponse, "response", null) === null) {
      return true;
    }

    return _isNil(_get(this.apiResponse, "error", null)) !== true;
  }

  get hasData(): boolean {
    return this.hasNoData === false;
  }

  get hasNoData(): boolean {
    return _get(this.apiResponse, "error.code", "") === "warn_no_data";
  }

  get(path: string) {
    return _get(this.apiResponse, path, null);
  }
}
