import React from "react";
import LoginPage from "../react/pages/authentication/login/";
import { Providers } from "../react/common/layouts/Layout";

export default function LoginGatsbyEntry(props) {
  return (
    <Providers>
      <LoginPage {...props} />
    </Providers>
  );
}
