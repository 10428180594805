import React, { useState, useEffect } from "react";
import {
  FormField,
  Text,
  Icon,
  Link,
  Stack,
  Hide,
  Button,
  Heading,
  Box
} from "pws-design-system/design-system/";
import { ButtonProps } from "pws-design-system/design-system/components/button/Button";
import Layout from "../common/Layout";
import { Form, LoadingButton } from "../common";
import { Link as ReachLink, navigate } from "gatsby";
import _get from "lodash.get";
import { useMessages } from "../../../common/hooks/useMessages";
import axios from "axios";
import { formDataToJson } from "../../../../utils";
import { useAuthTokenStore } from "../../../common/hooks/stores/useAuthTokenStore";
import LoginResponse from "../../../../models/pws/Login";
import { ThemeContainer } from "../../../common/hooks/useTheme";

class ExtendableError extends Error {
  detail = null;
  message = "";
  constructor(detail, message) {
    super(message);
    this.name = this.constructor.name;
    this.detail = detail;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  get code() {
    return _get(this.detail, "code", null);
  }
}

class UnauthorizedLoginError extends ExtendableError {
  constructor(detail, message = "") {
    super(
      {
        code: "unauthorized",
        ...detail
      },
      message
    );
  }
}

interface SubmitButtonProps extends ButtonProps {
  isLoading: Boolean;
}

function SubmitButton({ isLoading, ...rest }: SubmitButtonProps): React.ReactElement {
  return (
    <LoadingButton
      type="submit"
      isLoading={isLoading}
      {...rest}
      cy="cy-login-page-login-form-submit-button"
    >
      Log In
    </LoadingButton>
  );
}

function SignUpButton({ ...rest }) {
  const { theme } = ThemeContainer.useContainer();
  return (
    <Button
      variant="outline"
      variantColor={theme.components.dashboard.logo.variant}
      {...rest}
      onClick={() => navigate("/register")}
    >
      Sign Up
    </Button>
  );
}

function SubmitSlot() {
  return (isLoading: Boolean) => (
    <>
      <Hide mobile>
        <Stack isInline mt={4}>
          <SubmitButton isLoading={isLoading} />
          <SignUpButton />
        </Stack>
      </Hide>
      <Hide desktop tablet>
        <Stack mt={4}>
          <SubmitButton isLoading={isLoading} width={["100%", null, null, "auto"]} />
          <SignUpButton width={["100%", null, null, "auto"]} />
        </Stack>
      </Hide>
    </>
  );
}

function Flash({ message }: { message: string }) {
  return (
    <Box bg="green.100" mt={2} p={2} mx={-2} rounded="md">
      <Text variant="caption1" color="green.700" dangerouslySetInnerHTML={{ __html: message }} />
    </Box>
  );
}

const errorMap = {
  unauthorized: {
    message: () => "Login failed. You must use your email to log in. Please try again."
  },
  validation_error: {
    message: () => "Your email address was invalid. Please try again."
  },
  unknown: {
    message: () =>
      "An unknown error occured. Please try again. If the error persists, please <a href='/support' style='text-decoration: underline'>contact our support team</a>."
  }
};

function LoginPageDisplay(props): React.ReactElement {
  const { theme } = ThemeContainer.useContainer();
  const messages = useMessages();
  const flash = _get(props, "location.state.flash", null);
  const { authToken, setAuthToken } = useAuthTokenStore();
  const [authTokenLocal, setAuthTokenLocal] = useState();

  const errorHandler = (errorText, errorObj) => {
    console.log(errorObj);
  };

  const validateResponse = response => {};

  const handleSubmit = async (
    event,
    setErrors,
    send,
    action,
    withCredentials,
    successHandler,
    errorHandler
  ) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    setErrors(null);
    send("submitting");

    try {
      const apiResponse = await axios({
        url: action,
        method: "POST",
        withCredentials,
        headers: {
          "Content-Type": "application/json"
        },
        data: formDataToJson(formData)
      });

      const response = new LoginResponse(apiResponse.data);

      response.validate();

      if (response.loggedIn) {
        send("success");

        setAuthToken(response.authToken);
        setAuthTokenLocal(response.authToken);
        successHandler();
      } else {
        throw new UnauthorizedLoginError();
      }
    } catch (error) {
      if (_get(error, "response.status", false) === 401) {
        setErrors(errorMap["unauthorized"].message());
      } else if (_get(error, "response.status", false) === 400) {
        setErrors(errorMap["validation_error"].message());
      } else {
        const code = error.code;
        const errorMessage = errorMap[code];
        if (errorMessage) {
          setErrors(errorMessage.message());
        } else {
          setErrors(errorMap.unknown.message());
        }
      }

      send("errored");
    }
  };

  useEffect(() => {
    if (authTokenLocal) {
      window.location.replace(
        `${process.env.GATSBY_PROD_URL}${process.env.GATSBY_STATION_LIST_URL}?authToken=${authTokenLocal}`
      );
    }
  }, [authTokenLocal]);

  return (
    <Layout
      metaTitle={_get(messages, "login_page_title", "PWSWeather")}
      metaDescription={_get(messages, "login_page_meta_description", "PWSWeather")}
    >
      <Form
        HeadingSlot={
          <Heading as="h1" variant="headline">
            Log in to your account
          </Heading>
        }
        submitHandler={handleSubmit}
        successHandler={() => {}}
        errorHandler={errorHandler}
        action={`${process.env.GATSBY_PWS_API_URL}${process.env.GATSBY_LOGIN_URL}`}
        FlashSlot={flash && <Flash message={props.location.state.flash} />}
        SubmitSlot={SubmitSlot()}
      >
        <Box mb={4}>
          <Stack spacing={4}>
            <FormField
              type="text"
              name="email"
              label="Email"
              field={{
                variant: "flushed",
                rightElement: <Icon name="user" color="gray.300" />,
                background: theme.colors.bg.base.primary
              }}
              required
            />
            <FormField
              type="password"
              name="password"
              label="Password"
              field={{
                type: "password",
                variant: "flushed",
                rightElement: <Icon name="lock" color="gray.300" />,
                background: theme.colors.bg.base.primary
              }}
              required
            />
            <Text mt={-3} variant="caption2" textAlign="right">
              <Link as={ReachLink} to="/forgot-password">
                Forgot password?
              </Link>
            </Text>
          </Stack>
        </Box>
      </Form>
    </Layout>
  );
}

export default function LoginPage(props) {
  return <LoginPageDisplay {...props}>{props.children}</LoginPageDisplay>;
}
